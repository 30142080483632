import React from 'react'
import ProductSingle from '../components/ProductSingle';


function ViewProductSingle() {

	return (
		<div className="ViewSingleProduct">
			<ProductSingle />
		</div>
	);
}
export default ViewProductSingle


